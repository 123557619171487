<template>
	<section class="product-overview">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div class="max-width-1000">
			<div class="row" align-x="right" align-y="center" gutter="5">
				<router-link to="/cart">
					<el-button icon="el-icon-shopping-cart-1" type="text" style="font-size:1em; color:#000;">購物車</el-button>
				</router-link>
				<b>｜</b>
				<router-link to="/order">
					<el-button icon="el-icon-s-order" type="text" style="font-size:1em; color:#000;">查看訂單</el-button>
				</router-link>
			</div>
			<quote-title label="百貨舖" />
			<br>
			<br>
			<div class="row-grid" gutter-x="20" gutter-y="20">
				<div v-for="(item, i) in _productList" class="rwd-l-3 rwd-m-4 rwd-s-6" :key="item.id || i">
					<el-badge :value="item | badgeValueFilter">
						<router-link :to="`/product/${item.id}`" class="col">
							<thumb :src="item.thumb" square />
							<br>
							<div class="col" align-x="left">
								<p v-if="item.name" style="font-size:1.3em;">{{item.name}}</p>
								<table>
									<tr>
										<th>定價</th>
										<td>{{item.price | currencyFilter}}</td>
									</tr>
									<tr>
										<th>椅友價</th>
										<td>{{item.special_price | currencyFilter}}</td>
									</tr>
								</table>
							</div>
							<br>
						</router-link>
					</el-badge>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Thumb: () => import("@/components/thumb")
	},
	computed: {
		...mapState("product", ["_productList"]),
	},
	filters: {
		badgeValueFilter({ amount }) {
			return !amount ? "缺" : "";
		},
	},
	methods: {
		...mapActions("product", [
			"_getOnSaleList"
		]),
	},
	async created() {
		await this._getOnSaleList();
	}
}
</script>

<style lang="scss" scoped>
.product-overview {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush1.png"),
		url("../../../assets/background/dot1.png");
	background-position: center bottom, center 80%;

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 250px;
		bottom: 5%;
		right: 20%;
	}

	table {
		text-align: left;

		td {
			padding-left: 1em;
			color: #cb2222;
		}
	}

	.el-badge {
		width: 100%;
	}
}
</style>